import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import("../views/index/index.vue"),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("../views/index/index.vue")
  },
  {
    path: '/dynamics',
    name: 'dynamics',
    component: () => import("../views/dynamics/dynamics.vue")
  },
  {
    path: '/activityRecruitment',
    name: 'activityRecruitment',
    component: () => import("../views/activityRecruitment/activityRecruitment.vue")
  },{
    path: '/activityAnnouncement',
    name: 'activityAnnouncement',
    component: () => import("../views/activityAnnouncement/activityAnnouncement.vue")
  },{
    path: '/preciseDocking',
    name: 'preciseDocking',
    component: () => import("../views/preciseDocking/preciseDocking.vue")
  },{
    path: '/preciseDockingDetail',
    name: 'preciseDockingDetail',
    component: () => import("../views/preciseDocking/preciseDockingDetail.vue")
  },
  {
    path: '/loveMall',
    name: 'loveMall',
    component: () => import("../views/loveMall/loveMall.vue")
  },{
    path: '/loveMallDetail',
    name: 'loveMallDetail',
    component: () => import("../views/loveMall/loveMallDetail.vue")
  },
  {
    path: '/details',
    name: 'details',
    component: () => import("../views/detailView/details.vue")
  },
  

  {
    path: '/recruitmentDetail',
    name: 'recruitmentDetail',
    component: () => import("../views/activityRecruitment/recruitmentDetail.vue")
  },{
    path: '/announcementDetail/:id',
    name: 'announcementDetail',
    component: () => import("../views/activityAnnouncement/announcementDetail.vue")
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import("../views/practice/practice.vue")
  },
  {
    path: '/registNotice',
    name: 'registNotice',
    component: () => import("../views/regist/registNotice.vue")
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import("../views/regist/regist.vue")
  },
  {
    path: '/map',
    name: 'map',
    component: () => import("../views/map/map.vue")
  },
  {
    path: '/train',
    name: 'train',
    component: () => import("../views/train/train.vue")
  },
  {
    path: '/trainDetail',
    name: 'trainDetail',
    component: () => import("../views/train/trainDetail.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
